
import { Component, Vue } from 'vue-property-decorator'
import { dateBefore } from '@/utils/formatDate'
@Component
export default class History extends Vue {
  private searchInfo: { dateRange: Array<string> } = {
    dateRange: []
  }

  private dateRange: {min: string | null; max: string | null } = {
    min: null,
    max: null
  }

  private pickerOptions = {
    // 选中日期后会执行的回调
    // 日期范围不超过30天 ，选中当天之前的时期
    onPick: (date: {maxDate: any; minDate: any}) => {
      this.dateRange.max = date.maxDate
      this.dateRange.min = date.minDate
    },
    disabledDate: (time: any) => {
      if (this.dateRange.min) {
        return (time.getTime() > new Date(this.dateRange.min).getTime() + 60 * 60 * 24 * 30 * 1000) ||
        (time.getTime() >= Date.now()) ||
        (time.getTime() < new Date(this.dateRange.min).getTime() - 60 * 60 * 24 * 30 * 1000)
      } else {
        return time.getTime() >= Date.now()
      }
    }
  }

  private tableData: { loading: boolean; data: Array<{collectTime: string; waterLevel: string }> } = {
    loading: false,
    data: []
  }

  page = 1
  size = 10
  total = 0

  get deviceId () {
    return this.$route.params.deviceId as string || ''
  }

  get deviceNumber () {
    return this.$route.query.deviceNumber as string || ''
  }

  created () {
    this.initTime()
    this.getData()
  }

  initTime () {
    this.searchInfo.dateRange = dateBefore(29, true)
  }

  addZero (val: number) {
    return val < 10 ? '0' + val : String(val)
  }

  getData (): void {
    if (this.searchInfo.dateRange && this.searchInfo.dateRange[0]) {
      this.tableData.loading = true
      this.$axios.get(this.$apis.devices.getHistoryByTypeId('1028'), {
        deviceId: this.deviceId,
        startTime: this.searchInfo.dateRange[0],
        endTime: this.searchInfo.dateRange[1],
        page: this.page,
        size: this.size
      }).then((res) => {
        this.total = res.total || 0
        this.tableData.data = res.list || []
      }).finally(() => {
        this.tableData.loading = false
      })
    } else {
      this.$message.error('请选择查询日期范围')
    }
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  getState (val: string) {
    switch (val) {
      case '1':
        return '正常'
      case '2':
        return '低压告警'
      case '3':
        return '高压告警'
      default:
        return '--'
    }
  }
}
